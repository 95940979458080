import { Trans, useTranslation } from "react-i18next";
import JobillaLogo from "~/components/jobilla-logo";
import { StyledLink } from "~/components/styled-link";

export default function ResetPasswordSuccessPage() {
  const { t } = useTranslation("reset-password-success-page");

  return (
    <div className="h-full flex flex-col justify-center px-16">
      <div className="w-36 mx-auto">
        <JobillaLogo />
      </div>

      <div className="mt-8 space-y-6 text-center leading-relaxed">
        <p className="text-5xl font-semibold leading-tight">{t("page_title")}</p>
        <p>{t("page_subtext")}</p>
      </div>

      <div className="mt-8 space-y-6 text-center leading-relaxed">
        <StyledLink to="/login">
          {t("go_to_login_link.label", { ns: "reset-password-page" })}
        </StyledLink>
      </div>

      <div className="mt-8 md:mt-16 text-center text-sm">
        <p>{t("urgent.line")}</p>
        <p>
          <Trans
            t={t}
            i18nKey="urgent.line_2"
            values={{ phone: "+358 40 187 5214" }}
            // eslint-disable-next-line jsx-a11y/anchor-has-content -- The content is generated by i18n
            components={[<a key="phone" href="tel: +358401875214" className="font-bold" />]}
          />
        </p>
      </div>
    </div>
  );
}
