import { useTranslation } from "react-i18next";
import { redirect } from "react-router-dom";
import JobillaLogo from "~/components/jobilla-logo";
import { StyledAnchor } from "~/components/styled-anchor";
import { fetchPermissions } from "~/api/permissions";

export async function loader() {
  try {
    const permissions = await fetchPermissions();

    if (!Object.values(permissions.units).some((unit) => unit.length > 0)) {
      return redirect("/login");
    }
  } catch (e) {
    return redirect("/login");
  }

  return null;
}

export default function LoginSuccessPage() {
  const { t } = useTranslation("login-successful-page");

  return (
    <div className="h-full flex flex-col justify-center px-16">
      <div className="w-36 mx-auto">
        <JobillaLogo />
      </div>

      <div className="mt-8 space-y-6 text-center leading-relaxed">
        <p className="text-5xl font-semibold">{t("page_title")}</p>
        <p>{t("page_subtext")}</p>

        <ul className="list-style-none flex flex-col space-y-2">
          <li>
            <StyledAnchor href={import.meta.env.VITE_ATS_APP_URL}>
              Go to TAS (Talent acquisition solution)
            </StyledAnchor>
          </li>

          <li>
            <StyledAnchor href={import.meta.env.VITE_JOM_APP_URL}>
              Go to JOM (Jobilla order management)
            </StyledAnchor>
          </li>
        </ul>
      </div>
    </div>
  );
}
