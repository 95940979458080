import * as React from "react";
import { AuthOptions } from "~/entities/AuthOptionsResponse";
import { useCallback } from "react";

export default function SSOButton(option: AuthOptions) {
  const handleClick = useCallback(() => {
    window.location.href = `${import.meta.env.VITE_API_BASE}/authentication/v1/login/${option.id}`;
  }, [option.id]);

  return (
    <button
      type="button"
      className="rounded-full shadow bg-[#710ce2] w-full text-white font-medium px-6 py-3 leading-4 text-sm"
      onClick={handleClick}
    >
      {option.name}
    </button>
  );
}
