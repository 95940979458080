import { FC, Suspense, useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useOutlet, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { boot, createInstaller, shutdown } from "~/services/intercom";
import heptagons from "~/static/heptagons.svg";
import loginImage from "~/static/login.svg";

export default function Root() {
  const { t, i18n } = useTranslation("root");
  const location = useLocation();

  if (import.meta.env.PROD) {
    useEffect(() => {
      createInstaller({ app_id: import.meta.env.VITE_INTERCOM_APP_ID });
      boot({ app_id: import.meta.env.VITE_INTERCOM_APP_ID });

      return () => {
        shutdown();
      };
    }, []);
  }

  const [searchParams, setSearchParams] = useSearchParams();

  const onLanguageChange = useCallback(() => {
    if (!searchParams.has("lang")) {
      return;
    }

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("lang");

    setSearchParams(newSearchParams);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    i18n.on("languageChanged", onLanguageChange);
    return () => i18n.off("languageChanged", onLanguageChange);
  });

  return (
    <Suspense fallback={null}>
      <div className="bg-[#550aaa] min-h-screen text-stone-700 md:h-screen">
        <div className="h-full flex flex-col md:flex-row">
          <div className="relative hidden md:block flex-1">
            <div
              className="absolute inset-0 bg-no-repeat"
              style={{
                backgroundImage: `url(${heptagons})`,
                backgroundPosition: "center right -50px",
              }}
            >
              <div
                className="absolute inset-0 bg-no-repeat"
                style={{
                  backgroundImage: `url(${loginImage})`,
                  backgroundPosition: "center right clamp(20px, 5vw, 100px)",
                }}
              >
                <div
                  className="
                  absolute text-white text-lg text-center
                  left-[max(30px,_5vw)] right-[max(30px,_5vw)] top-[calc(50%-230px)]
                  xl:w-[550px] xl:left-[unset] xl:right-[80px]
                "
                >
                  {t("slogan")}
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1 md:h-full py-3">
            <div className="h-full md:overflow-auto py-6 bg-white md:rounded-l-2xl">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
}

const AnimatedOutlet: FC = () => {
  const o = useOutlet();
  const [outlet] = useState(o);

  return <>{outlet}</>;
};
