import { SVGProps, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BritishFlag } from "~/components/flags/en-GB";
import { FinnishFlag } from "~/components/flags/fi-FI";
import { GermanFlag } from "~/components/flags/de-DE";
import { LatvianFlag } from "~/components/flags/lv-LV";
import { LithuanianFlag } from "~/components/flags/lt-LT";

export default function LanguagePicker() {
  const { i18n } = useTranslation();

  const changeLanguage = useCallback(
    (lang: string) => {
      i18n.changeLanguage(lang);
    },
    [i18n],
  );

  return (
    <div className="flex items-start space-x-3">
      {Object.keys(languages).map((key) => (
        <button
          key={key}
          onClick={() => changeLanguage(key)}
          className="focus:outline-none focus:ring"
        >
          {languages[key].flag({
            className: `w-5 h-5 flex-shrink-0 ${
              i18n.language === key ? "opacity-100" : "opacity-50"
            }`,
          })}
        </button>
      ))}
    </div>
  );
}

const languages: Record<
  string,
  { label: string; flag: (args: SVGProps<SVGSVGElement>) => JSX.Element }
> = {
  "en-GB": {
    label: "English",
    flag: BritishFlag,
  },
  "fi-FI": {
    label: "Suomi",
    flag: FinnishFlag,
  },
  "de-DE": {
    label: "Deutsch",
    flag: GermanFlag,
  },
  "lv-LV": {
    label: "Latvian",
    flag: LatvianFlag,
  },
  "lt-LT": {
    label: "Lithuanian",
    flag: LithuanianFlag,
  },
};
