import { ResponsePromise } from "ky";
import { api } from "~/api/base";

export async function requestPasswordReset(email: string): Promise<ResponsePromise> {
  return api.post("api/v2/user/password-reset", { json: { email } });
}

export async function checkResetTokenValidity(email: string, token: string): Promise<boolean> {
  try {
    await api.get(`password/reset/${email}/${token}`);
    return true;
  } catch (e) {
    return false;
  }
}

export interface PasswordResetParams {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
}

export async function resetPassword(data: PasswordResetParams): Promise<ResponsePromise> {
  return api.post(`password/reset`, { json: data });
}
