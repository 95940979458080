import { ActionFunctionArgs, Form, redirect, useActionData, useNavigation } from "react-router-dom";
import { HTTPError } from "ky";
import { useTranslation } from "react-i18next";
import { requestPasswordReset } from "~/api/password-reset";
import { SpinnerIcon } from "~/components/spinner";
import { StyledLink } from "~/components/styled-link";

export async function action({ request }: ActionFunctionArgs) {
  const input = Object.fromEntries((await request.formData()).entries()) as Record<string, string>;

  if (input.email?.search(/^\S+@\S+\.\S+$/) === -1) {
    return {
      status: 422,
      errors: {
        email: ["validation:email.invalid"],
      },
    };
  }

  try {
    await requestPasswordReset(input.email);
    return redirect("/reset-password-successful");
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      const responseData: { errors: { [key: string]: string[] } } = await e.response.json();

      return {
        status: 422,
        errors: responseData.errors,
      };
    }

    return { status: 500 };
  }
}

export default function ResetForm() {
  const { t } = useTranslation("reset-password-page");

  const result = useActionData() as
    | Exclude<Awaited<ReturnType<typeof action>>, Response>
    | undefined;
  const navigation = useNavigation();

  const isSuccessfulSubmission = result?.status && result?.status >= 200 && result?.status < 300;
  const hasUnexpectedError = result?.status && result?.status !== 422 && result?.status >= 300;

  return (
    <Form noValidate method="post" className="space-y-4 md:space-y-10">
      <div>
        <label htmlFor="email" className="inline-block text-neutral-800 font-bold mb-2">
          {t("form.email.label")}
        </label>

        <input
          type="text"
          id="email"
          name="email"
          required
          className="
            w-full py-2 px-3 bg-white
            appearance-none leading-none
            rounded border border-transparent
            ring-1 ring-neutral-300
            focus:outline-none focus:ring-2 focus:ring-sky-300
            transition duration-150
          "
          placeholder="email@mycompany.com"
        />

        {result?.errors?.email && (
          <p className="text-red-700 text-sm mt-2">
            {result.errors.email.map((e) => t(e)).join(" ")}
          </p>
        )}
      </div>

      {isSuccessfulSubmission && (
        <div className="border-l-4 border-l-green-600 bg-gray-200 p-6 text-neutral-800 space-y-4 leading-loose">
          <p className="font-medium">{t("form.alert_boxes.successful_submission.title")}</p>
          <p>{t("form.alert_boxes.successful_submission.message")}</p>
          <p>{t("form.alert_boxes.successful_submission.check_inbox")}</p>
        </div>
      )}

      {hasUnexpectedError && (
        <div className="border-l-4 border-l-red-600 bg-gray-200 p-6 text-neutral-800 space-y-4 leading-loose">
          <p className="font-medium">{t("form.alert_boxes.unexpected_error.title")}</p>
          <p>{t("form.alert_boxes.unexpected_error.message")}</p>
        </div>
      )}

      <div>
        <button
          type="submit"
          className={[
            "rounded-full shadow bg-[#710ce2] text-white font-medium w-full px-6 py-3 leading-4 text-sm",
            navigation.state !== "idle" && "opacity-50 cursor-wait italic",
          ].join(" ")}
          disabled={navigation.state !== "idle"}
        >
          {navigation.state !== "idle" ? (
            <SpinnerIcon className="inline-block w-4 h-4 animate-spin" />
          ) : (
            t("form.submit_button.label")
          )}
        </button>
      </div>

      <div className="text-center">
        <StyledLink to="/login">{t("go_to_login_link.label")}</StyledLink>
      </div>
    </Form>
  );
}
