import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import I18nextBrowserLanguageDetector, {
  DetectorOptions as I18nextBrowserLanguageDetectorOptions,
} from "i18next-browser-languagedetector";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

// eslint-disable-next-line import/no-named-as-default-member
export default i18next
  .use(HttpBackend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init<HttpBackendOptions & I18nextBrowserLanguageDetectorOptions>({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    detection: {
      order: ["querystring", "localStorage"],
      lookupQuerystring: "lang",
    },
    load: "all",
    ns: [
      "root",
      "validation",
      "login-page",
      "reset-password-page",
      "recover-page",
      "login-successful-page",
      "reset-password-success-page",
    ],
    fallbackLng: "en-GB",
    supportedLngs: ["en-GB", "fi-FI", "de-DE", "lt-LT", "lv-LV"],
    returnNull: false,
    react: {
      transKeepBasicHtmlNodesFor: ["p"],
    },
  });
