import { EntityBuilder, PropsJson } from "@jobilla/entity";
import Cookies, { CookieAttributes } from "js-cookie";
import { add } from "date-fns";
import { api } from "~/api/base";
import { AuthOptionsResponse } from "~/entities/AuthOptionsResponse";
import { LoginResponse } from "~/entities/LoginResponse";
import {setAuthCookies} from "~/services/auth";

interface SubmitSSOLoginArgs {
  code: string;
  state?: string | null;
  sessionState?: string | null;
  id: string;
}

export async function getAuthOptions(email: string): Promise<AuthOptionsResponse> {
  const response = await api
    .get(`authentication/v1/options/${email}`, {})
    .json<PropsJson<AuthOptionsResponse>>();

  return EntityBuilder.buildOne(AuthOptionsResponse, response);
}

export async function getCompanyAuthOptions(companyName: string): Promise<AuthOptionsResponse> {
  const response = await api
    .get(`authentication/v1/authenticators/${companyName}`, {})
    .json<PropsJson<AuthOptionsResponse>>();

  return EntityBuilder.buildOne(AuthOptionsResponse, response);
}

export async function submitSSOLogin({
  code,
  state,
  sessionState,
  id,
}: SubmitSSOLoginArgs): Promise<LoginResponse> {
  const response = await api
    .post(`authentication/v1/login/${id}`, {
      json: {
        code: code,
        state: state,
        session_state: sessionState,
      },
    })
    .json<PropsJson<LoginResponse>>();

  const responseEntity = EntityBuilder.buildOne(LoginResponse, response)

  setAuthCookies(responseEntity);

  return responseEntity;
}
