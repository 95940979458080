export interface IntercomCredentials {
  app_id: string;
  name?: string;
  email?: string;
  user_id?: number;
  user_hash?: string;
  jobilla_language?: string;
}

type OperationsWithoutArguments = "shutdown" | "openMessages" | "show" | "reattach_activator";

declare global {
  interface Window {
    Intercom: Intercom;
  }
}

interface Intercom {
  (operation: OperationsWithoutArguments): void;
  (operation: "boot", credentials?: IntercomCredentials): void;
  (operation: "update", credentials?: IntercomCredentials): void;
  (operation: "showNewMessage", prePopulatedMessage?: string): void;

  q?: unknown[];
  c?: (args: unknown[]) => void;
}

export function createInstaller(credentials: IntercomCredentials) {
  if (typeof window.Intercom === "function") {
    window.Intercom("reattach_activator");
    window.Intercom("update", credentials);
  } else {
    window.Intercom = function (...args: unknown[]) {
      // Disabled this rule here because we _know_ window.Intercom.c is a function. We create it in the next statement.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      window.Intercom.c!(args);
    };

    window.Intercom.c = function (args) {
      // Disabled this rule here because we _know_ window.Intercom.q exists. We create it in the next statement.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      window.Intercom.q!.push(args);
    };

    window.Intercom.q = [];

    const insertIntercomScript = () => {
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${credentials.app_id}`;
      const x = document.getElementsByTagName("script")[0];
      // Disabled this rule here because we _know_ x.parentNode exists. Any element found within `document` will have a parent node.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      x.parentNode!.insertBefore(s, x);
    };

    insertIntercomScript();
  }
}

export function boot(credentials: IntercomCredentials) {
  window.Intercom("boot", credentials);
}

export function shutdown() {
  window.Intercom("shutdown");
}

export function newMessage(prepopulatedMessage?: string) {
  window.Intercom("showNewMessage", prepopulatedMessage);
}
