import LanguagePicker from "~/components/language-picker";
import JobillaLogo from "~/static/jobilla-logo.png";
import LoginForm from "~/pages/login/form";

export default function Login() {
  return (
    <div className="h-full grid grid-rows-[1fr,_4fr,_1fr]">
      <div className="flex justify-end px-8 py-2">
        <LanguagePicker />
      </div>

      <div className="px-6 md:px-8 self-center flex flex-col items-stretch space-y-4">
        <img src={JobillaLogo} alt="" className="block mx-auto max-w-[128px]" />

        <LoginForm />
      </div>
    </div>
  );
}
