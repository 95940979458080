import { ValidationError } from "yup";

export function processYupErrors(error: ValidationError) {
  const errorPairs = error.inner
    .map((innerError): [string, string[]] | null => {
      if (!innerError.path) {
        return null;
      }

      return [innerError.path, innerError.errors];
    })
    .filter((pair): pair is [string, string[]] => pair !== null);

  return Object.fromEntries(errorPairs);
}
