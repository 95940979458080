import { Link } from "react-router-dom";
import { ComponentProps } from "react";

export type StyledLinkArgs = ComponentProps<typeof Link>;

export function StyledLink({ children, ...props }: StyledLinkArgs) {
  return (
    <Link
      {...props}
      className="text-[#7453ab] underline underline-offset-2 decoration-2 decoration-blue-200 text-sm hover:underline"
    >
      {children}
    </Link>
  );
}
