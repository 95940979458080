import { Link, LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import LanguagePicker from "~/components/language-picker";
import JobillaLogo from "~/components/jobilla-logo";
import RecoverForm, { action } from "~/pages/recover/form";
import { checkResetTokenValidity } from "~/api/password-reset";

export { action };

export async function loader({ request }: LoaderFunctionArgs) {
  const query = new URL(request.url).searchParams;
  const email = query.get("email");
  const token = query.get("token");

  if (!email || !token) {
    return {
      isTokenValid: false,
    };
  }

  return {
    isTokenValid: await checkResetTokenValidity(email, token),
  };
}

export default function ResetPasswordPage() {
  const { isTokenValid } = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  return (
    <div className="h-full grid grid-rows-[1fr,_4fr,_1fr]">
      <div className="flex justify-end px-8 py-2">
        <LanguagePicker />
      </div>

      <div className="px-6 md:px-8 self-center flex flex-col items-stretch space-y-8">
        <JobillaLogo />

        {isTokenValid ? <RecoverForm /> : <InvalidToken />}
      </div>
    </div>
  );
}

function InvalidToken() {
  const { t } = useTranslation("recover-page");

  return (
    <div className="space-y-6 text-stone-700 text-center">
      <p className="text-5xl font-semibold">{t("invalid_link.title")}</p>
      <p>
        <Trans
          t={t}
          i18nKey="invalid_link.message"
          components={[
            <Link key="reset-link" to="/reset-password" className="text-blue-500 underline" />,
          ]}
        />
      </p>
    </div>
  );
}
