import { Entity } from "@jobilla/entity";

export interface AuthOptions {
  id: string;
  name: string;
  is_required: number;
}
export class AuthOptionsResponse extends Entity {
  data!: AuthOptions[];
}
