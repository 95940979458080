import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, redirect, RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";
import "~/i18n";
import "./index.css";
import { Suspense } from "react";
import LoginPage from "~/pages/login/index";
import LoginSuccessPage, { loader as LoginSuccessPageLoader } from "~/pages/login-successful/index";
import { action as LoginPageAction } from "~/pages/login/form";
import { loader as LoginPageLoader } from "~/pages/login/form";
import ResetPasswordPage, { action as ResetPasswordAction } from "~/pages/reset-password/index";
import RecoverPage, {
  action as RecoverAction,
  loader as RecoverLoader,
} from "~/pages/recover/index";
import { init as initSentry } from "~/services/sentry";
import ResetPasswordSuccessPage from "~/pages/reset-password-successful";
import Root from "./root";
import SsoLogin, { loader as SsoLoginLoader } from "~/pages/sso-login";

initSentry();

const createBrowserRouterWithSentry = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = createBrowserRouterWithSentry([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        index: true,
        loader: () => redirect("/login"),
      },
      {
        path: "login/:companyName?/:ssoId?",
        action: LoginPageAction,
        loader: LoginPageLoader,
        element: <LoginPage />,
      },
      {
        path: "sso-login/:companyName?/:ssoId?",
        loader: SsoLoginLoader,
        element: <SsoLogin />,
      },
      {
        path: "login-successful",
        loader: LoginSuccessPageLoader,
        element: <LoginSuccessPage />,
      },
      {
        path: "reset-password",
        action: ResetPasswordAction,
        element: <ResetPasswordPage />,
      },
      {
        path: "reset-password-successful",
        element: <ResetPasswordSuccessPage />,
      },
      {
        path: "recover",
        loader: RecoverLoader,
        action: RecoverAction,
        element: <RecoverPage />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    {/* Suspending the app during translation loads */}
    <Suspense>
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>,
);
