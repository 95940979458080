import ky from "ky";
import { getAccessToken } from "~/services/auth";

export const api = ky.create({
  prefixUrl: import.meta.env.VITE_API_BASE,
  hooks: {
    beforeRequest: [
      (request) => {
        const accessToken = getAccessToken();
        if (accessToken) {
          request.headers.set("Authorization", `Bearer ${accessToken}`);
        }
      },
    ],
  },
  headers: {
    Accept: "application/json",
  },
});
