import { useActionData, useNavigation } from "react-router-dom";

/**
 * This hook returns the pure action data, but only if NO forms are being submitted.
 */
export function useActiveActionData() {
  const transition = useNavigation();
  const actionData = useActionData();

  if (transition.state !== "submitting") {
    return actionData;
  }

  return undefined;
}
