import * as Yup from "yup";
import { ActionFunctionArgs, Form, redirect, useActionData, useNavigation } from "react-router-dom";
import { HTTPError } from "ky";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import invariant from "tiny-invariant";
import { captureException } from "@sentry/react";
import { processYupErrors } from "~/services/yup-errors";
import { resetPassword } from "~/api/password-reset";
import { SpinnerIcon } from "~/components/spinner";

function validateInput(input: Record<string, string>) {
  const schema = Yup.object().shape({
    password: Yup.string()
      .min(8, t("validation:min_length", { min: 8 }))
      .required(t("validation:required")),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password")], t("validation:confirmed"))
      .required(t("validation:required")),
  });

  return schema.validate(input, { abortEarly: false });
}

export async function action({ request }: ActionFunctionArgs) {
  const searchParams = Object.fromEntries<string | undefined>(
    new URL(request.url).searchParams.entries(),
  );

  invariant(searchParams.email, "Missing email");
  invariant(searchParams.token, "Missing token");

  const input = Object.fromEntries((await request.formData()).entries()) as Record<string, string>;

  try {
    const validatedInput = await validateInput(input);

    await resetPassword({
      email: searchParams.email,
      token: searchParams.token,
      ...validatedInput,
    });

    return redirect("/login?password-reset=1");
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      return {
        status: 422,
        errors: processYupErrors(error),
      };
    }

    if (error instanceof HTTPError && error.response.status === 422) {
      return {
        status: 422,
        errors: (await error.response.json()).errors,
      };
    }

    captureException(error);

    return { status: 500 };
  }
}

export default function RecoverForm() {
  const { t } = useTranslation("recover-page");

  const result = useActionData() as
    | Exclude<Awaited<ReturnType<typeof action>>, Response>
    | undefined;
  const navigation = useNavigation();

  const hasUnexpectedError = result?.status && result?.status !== 422 && result?.status >= 300;

  return (
    <Form method="post" className="max-w-lg w-full mx-auto space-y-4 md:space-y-10">
      <div>
        <label htmlFor="password" className="inline-block text-neutral-800 font-bold mb-2">
          {t("form.password.label")}
        </label>

        <input
          type="password"
          id="password"
          name="password"
          required
          className="
            w-full py-2 px-3 bg-white
            appearance-none leading-none
            rounded border border-transparent
            ring-1 ring-neutral-300
            focus:outline-none focus:ring-2 focus:ring-sky-300
            transition duration-150
          "
        />

        {result?.errors?.password && (
          <p className="text-red-700 text-sm mt-2">{result.errors.password.join(" ")}</p>
        )}

        <p className="text-sm mt-2 text-stone-500">{t("pick_strong_password")}</p>
      </div>

      <div>
        <label
          htmlFor="password_confirmation"
          className="inline-block text-neutral-800 font-bold mb-2"
        >
          {t("form.password_confirmation.label")}
        </label>

        <input
          type="password"
          id="password_confirmation"
          name="password_confirmation"
          required
          className="
            w-full py-2 px-3 bg-white
            appearance-none leading-none
            rounded border border-transparent
            ring-1 ring-neutral-300
            focus:outline-none focus:ring-2 focus:ring-sky-300
            transition duration-150
          "
        />

        {result?.errors?.password_confirmation && (
          <p className="text-red-700 text-sm mt-2">
            {result.errors.password_confirmation.join(" ")}
          </p>
        )}
      </div>

      {hasUnexpectedError && (
        <div className="border-l-4 border-l-red-600 bg-gray-200 p-6 text-neutral-800 space-y-4 leading-loose">
          <p className="font-medium">{t("form.alert_boxes.unexpected_error.title")}</p>
          <p>{t("form.alert_boxes.unexpected_error.message")}</p>
        </div>
      )}

      <div>
        <button
          type="submit"
          className={[
            "rounded-full shadow bg-[#710ce2] text-white font-medium w-full px-6 py-3 leading-4 text-sm",
            navigation.state !== "idle" && "opacity-50 cursor-wait italic",
          ].join(" ")}
          disabled={navigation.state !== "idle"}
        >
          {navigation.state !== "idle" ? (
            <SpinnerIcon className="inline-block w-4 h-4 animate-spin" />
          ) : (
            t("form.submit_button.label")
          )}
        </button>
      </div>
    </Form>
  );
}
