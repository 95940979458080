import { add } from "date-fns";
import Cookies, { CookieAttributes } from "js-cookie";
import { submitLogin } from "~/api/auth";
import { LoginResponse } from "~/entities/LoginResponse";

interface LoginAttemptArgs {
  email: string;
  password: string;
}

export async function attemptLogin(credentials: LoginAttemptArgs): Promise<LoginResponse> {
  const response = await submitLogin(credentials);
  setAuthCookies(response);

  return response;
}

export function setAuthCookies(response: LoginResponse): void {
  const cookieOptions = {
    domain: import.meta.env.VITE_COOKIE_DOMAIN,
    sameSite: "strict",
    expires: add(new Date(), { seconds: response.expiresIn }),
    secure: import.meta.env.VITE_COOKIE_DOMAIN !== "localhost",
  } satisfies CookieAttributes;

  Cookies.set("auth.access-token", response.accessToken, cookieOptions);

  if (response.refreshToken) {
    Cookies.set("auth.refresh-token", response.refreshToken, cookieOptions);
  }
}

export function getAccessToken(): string | undefined {
  return Cookies.get("auth.access-token");
}
