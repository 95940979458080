import { api } from "~/api/base";

export type UserPermissionData = {
  companies: { [key: string]: string[] };
  groups: string[];
  objects: string[];
  units: { [key: string]: string[] };
};

export async function fetchPermissions(): Promise<UserPermissionData> {
  return await api.get("api/v2/user/permissions").json<UserPermissionData>();
}
