import LanguagePicker from "~/components/language-picker";
import ResetForm, { action } from "~/pages/reset-password/form";

export { action };

export default function ResetPasswordPage() {
  return (
    <div className="relative h-full grid grid-rows-[1fr,_4fr,_1fr]">
      <div className="flex justify-end px-8 py-2">
        <LanguagePicker />
      </div>

      <div className="px-6 md:px-8 self-center flex flex-col items-stretch space-y-4">
        <ResetForm />
      </div>
    </div>
  );
}
