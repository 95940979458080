import { HTTPError } from "ky";
import { redirect } from "react-router-dom";
import LanguagePicker from "~/components/language-picker";
import JobillaLogo from "~/static/jobilla-logo.png";
import { submitSSOLogin } from "~/api/sso";
import { AuthOptionsResponse } from "~/entities/AuthOptionsResponse";
import { LoginResponse } from "~/entities/LoginResponse";
import { fetchPermissions } from "~/api/permissions";
import AnimatedLoader from "~/components/animated-loader";

interface LoaderData {
  slugResult?: AuthOptionsResponse | null;
  result?: LoginResponse | null;
  status: number;
}

export async function loader({
  params: { ssoId },
  request,
}: {
  params: { ssoId?: string };
  request: Request;
}): Promise<LoaderData> {
  const searchParams = new URL(request.url).searchParams;

  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const sessionState = searchParams.get("session_state");
  const hasAzureCode = !!(code && state && sessionState);

  if ((hasAzureCode || code) && ssoId) {
    try {
      const result = await submitSSOLogin({ code, state, sessionState, id: ssoId });
      const redirectUrlInSessionStorage = sessionStorage.getItem("redirectUrl");

      if (!redirectUrlInSessionStorage) {
        try {
          const permissions = await fetchPermissions();

          if (!Object.values(permissions.units).some((unit) => unit.length > 0)) {
            throw new Error("No units");
          }
        } catch (e) {
          console.log(e);
          return redirect(import.meta.env.VITE_ATS_APP_URL);
        }

        return redirect("/login-successful");
      }

      const redirectURL = new URL(redirectUrlInSessionStorage);

      if (redirectURL.hostname === "localhost") {
        redirectURL.searchParams.set("access-token", result.accessToken);
      }

      return redirect(redirectURL.toString());
    } catch (e) {
      if (e instanceof HTTPError) {
        const status = e.response.status;
        return redirect(`/login?error=${status}`);
      }
    }
  }
  return { status: 500 };
}
export default function SsoLogin() {
  return (
    <div className="h-full grid grid-rows-[1fr,_4fr,_1fr]">
      <div className="flex justify-end px-8 py-2">
        <LanguagePicker />
      </div>

      <div className="px-6 md:px-8 self-center flex flex-col items-stretch space-y-4">
        <img src={JobillaLogo} alt="" className="block mx-auto max-w-[128px]" />
        <div className="flex flex-col items-center justify-center space-y-4">
          <p>We are logging you into Jobilla!</p>
          <AnimatedLoader className="inline-block max-w-[64px] origin-[50%_66%] animate-[spin_2s_linear_infinite]" />
        </div>
      </div>
    </div>
  );
}
