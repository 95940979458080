import { EntityBuilder, PropsJson } from "@jobilla/entity";
import { api } from "~/api/base";
import { LoginResponse } from "~/entities/LoginResponse";

interface SubmitLoginArgs {
  email: string;
  password: string;
}

interface SubmitGoogleLoginArgs {
  email: string;
  accessToken: string;
}

export async function submitLogin({ email, password }: SubmitLoginArgs): Promise<LoginResponse> {
  const response = await api
    .post("oauth/token", {
      json: {
        client_id: +import.meta.env.VITE_OAUTH_CLIENT_ID,
        client_secret: import.meta.env.VITE_OAUTH_CLIENT_SECRET,
        grant_type: "password",
        username: email,
        password,
      },
    })
    .json<PropsJson<LoginResponse>>();

  return EntityBuilder.buildOne(LoginResponse, response);
}

export async function submitGoogleLogin({
  email,
  accessToken,
}: SubmitGoogleLoginArgs): Promise<LoginResponse> {
  const response = await api
    .post("api/v2/social-login", {
      json: {
        username: email,
        token: accessToken,
        provider: "google",
      },
    })
    .json<PropsJson<LoginResponse>>();

  return EntityBuilder.buildOne(LoginResponse, response);
}
