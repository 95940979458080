import { useCallback, useEffect, useRef, useState } from "react";
import { HTTPError } from "ky";
import {
  ActionFunctionArgs,
  Form,
  Link,
  redirect,
  useLoaderData,
  useNavigation,
  useParams,
  useSearchParams,
  useSubmit,
} from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { attemptLogin } from "~/services/auth";
import { fetchPermissions } from "~/api/permissions";
import PasswordInput from "~/components/password-input";
import { useActiveActionData } from "~/hooks/useActiveActionData";
import { SpinnerIcon } from "~/components/spinner";
import SSOButton from "~/components/sso-button";
import { StyledLink } from "~/components/styled-link";
import { getAuthOptions, getCompanyAuthOptions } from "~/api/sso";
import { AuthOptionsResponse, AuthOptions } from "~/entities/AuthOptionsResponse";
import { LoginResponse } from "~/entities/LoginResponse";

interface LoginFormData {
  email_check_state: string;
  email: string;
  password: string;
}

interface ActionData {
  options?: AuthOptionsResponse;
  status: number;
}

interface LoaderData {
  slugResult?: AuthOptionsResponse | null;
  result?: LoginResponse | null;
  status: number;
}

export async function loader({
  params: { companyName, ssoId },
  request,
}: {
  params: { companyName?: string; ssoId?: string };
  request: Request;
}): Promise<LoaderData> {
  const searchParams = new URL(request.url).searchParams;

  const redirectUrlInSearchParams = searchParams.get("redirect");

  if (redirectUrlInSearchParams) {
    sessionStorage.setItem("redirectUrl", redirectUrlInSearchParams);
  }

  const error = searchParams.get("error");
  if (error) {
    searchParams.delete("error");
    return { status: parseInt(error, 10) };
  }

  const code = searchParams.get("code");

  if (code) {
    return redirect(
      `/sso-login/${companyName}/${ssoId}?code=${code}&state=${searchParams.get(
        "state",
      )}&session_state=${searchParams.get("session_state")}`,
    );
  }

  if (companyName) {
    try {
      const slugResult = await getCompanyAuthOptions(companyName);
      return { slugResult, status: 200 };
    } catch (e) {
      if (e instanceof HTTPError && e.response.status === 404) {
        return { status: 404 };
      }
    }
  }

  return { status: 201 };
}

export async function checkAuthOptions(email: string): Promise<ActionData> {
  try {
    const result = await getAuthOptions(email);
    return { options: result, status: 200 };
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 404) {
      return { status: 404 };
    }

    return { status: 500 };
  }
}

export async function login(data: LoginFormData, query: URLSearchParams) {
  try {
    const loginResult = await attemptLogin({ email: data.email, password: data.password });
    const redirectUrlInSessionStorage = sessionStorage.getItem("redirectUrl");

    if (!redirectUrlInSessionStorage) {
      try {
        const permissions = await fetchPermissions();

        if (!Object.values(permissions.units).some((unit) => unit.length > 0)) {
          throw new Error("No units");
        }
      } catch (e) {
        return redirect(import.meta.env.VITE_ATS_APP_URL);
      }

      return redirect("/login-successful");
    }

    const redirectURL = new URL(redirectUrlInSessionStorage);

    if (redirectURL.hostname === "localhost") {
      redirectURL.searchParams.set("access-token", loginResult.accessToken);
    }

    return redirect(redirectURL.toString());
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 401) {
      return { status: 422 };
    }

    return { status: 500 };
  }
}

export async function action({ request }: ActionFunctionArgs): Promise<ActionData> {
  const query = new URL(request.url).searchParams;
  const data = Object.fromEntries((await request.formData()).entries()) as unknown as LoginFormData;

  await new Promise((resolve) => setTimeout(resolve, 1000));
  if (data.email_check_state === "false") {
    return await checkAuthOptions(data.email);
  }
  return await login(data, query);
}

export default function LoginForm() {
  const { t } = useTranslation("login-page");

  const loaderData = useLoaderData() as LoaderData;
  const result: ActionData = useActiveActionData() as ActionData;
  const navigation = useNavigation();

  const [searchParams, setSearchParams] = useSearchParams();

  const params = useParams();

  const companyName = params.companyName;

  const hasSuccessfulPasswordResetAttempt = searchParams.get("password-reset") === "1";

  const loginButtonRef = useRef<HTMLButtonElement>(null);
  const loginFormRef = useRef<HTMLFormElement>(null);
  const submit = useSubmit();

  const [email, setEmail] = useState<string>("");

  const [emailCheckState, setEmailCheckState] = useState<string>("false");

  const isInvalidCredentials = result?.status === 422;
  const hasUnexpectedError = result?.status === 500;

  const isNoSSOFound = result?.status === 404;
  const hasSSOOptions = result?.status === 200;

  const hasSlugOptions = loaderData.status === 200;
  const hasSsoError = loaderData.status >= 405 && !result;

  const authOption = result?.options?.data as unknown as AuthOptions;
  const [isRequiredToUseSSO, setIsRequiredToUseSSO] = useState<boolean>(
    authOption?.is_required === 1,
  );

  useEffect(() => {
    if (!loginFormRef.current) {
      return;
    }

    if (isInvalidCredentials || hasUnexpectedError) {
      if (!loginFormRef.current?.password) {
        return;
      }

      loginFormRef.current.password.value = "";
    }
  }, [isInvalidCredentials, hasUnexpectedError]);

  useEffect(() => {
    if (authOption?.is_required === 1) {
      setIsRequiredToUseSSO(authOption?.is_required === 1);
    }
  }, [authOption]);

  useEffect(() => {
    if (isNoSSOFound || hasSSOOptions) {
      setEmailCheckState("true");
    }
  }, [isNoSSOFound, hasSSOOptions]);

  const changeEmailButtonAction = useCallback(() => {
    setEmailCheckState("false");
    setIsRequiredToUseSSO(false);
  }, []);

  return (
    <Form ref={loginFormRef} method="post" className="relative space-y-6 max-w-lg w-full mx-auto">
      <input type="hidden" name="email_check_state" value={emailCheckState} />

      {hasSuccessfulPasswordResetAttempt && (
        <div className="border-l-4 border-l-green-600 bg-gray-200 p-6 text-neutral-800">
          <p className="font-medium">{t("form.alert_boxes.successful_password_reset.title")}</p>
          <p>{t("form.alert_boxes.successful_password_reset.message")}</p>
        </div>
      )}

      {hasSlugOptions && (
        <div className="rounded-full bg-yellow-100 text-black text-center">
          Logging into Jobilla for <text className="capitalize font-bold">{companyName}</text>.
        </div>
      )}

      {emailCheckState === "true" && (
        <div className="space-x-1">
          <input type="hidden" name="email" value={email} />
          <p className="inline-block text-neutral-400 text-sm">{t("form.email_label.login")}</p>
          <p className="inline-block text-neutral-800 text-sm">{email}</p>
          <button
            type="button"
            className="inline-block text-blue-500 text-sm underline"
            onClick={changeEmailButtonAction}
          >
            ({t("form.email_label.change")})
          </button>
        </div>
      )}

      {emailCheckState === "false" && (
        <div>
          <label htmlFor="email" className="inline-block text-neutral-800 text-sm font-bold mb-2">
            {t("form.email.label")}
          </label>
          <input
            type="email"
            id="email"
            name="email"
            required
            className="
                w-full py-2 px-3 bg-white
                appearance-none leading-none
                rounded border border-transparent
                ring-1 ring-neutral-300
                focus:outline-none focus:ring-2 focus:ring-sky-300
                transition duration-150
              "
            placeholder="email@example.com"
            defaultValue={email}
            onInput={(event) => setEmail(event.currentTarget.value)}
          />
        </div>
      )}

      {emailCheckState === "true" && !isRequiredToUseSSO && (
        <div>
          <label
            htmlFor="password"
            className="inline-block text-neutral-800 text-sm font-bold mb-2"
          >
            {t("form.password.label")}
          </label>
          <PasswordInput
            id="password"
            name="password"
            className="
            w-full py-2 pl-3 pr-12 bg-white
            appearance-none leading-none
            rounded border border-transparent
            ring-1 ring-neutral-300
            focus:outline-none focus:ring-2 focus:ring-sky-300
            transition duration-150
          "
          />
        </div>
      )}

      {isInvalidCredentials && (
        <div className="border-l-4 border-l-red-600 bg-gray-200 p-6 text-neutral-800">
          <p className="font-medium">{t("form.alert_boxes.invalid_credentials.title")}</p>
          <p>
            <Trans
              t={t}
              i18nKey="form.alert_boxes.invalid_credentials.message"
              components={[
                <Link
                  key="reset-link"
                  to="/reset-password"
                  className="text-indigo-600 font-bold"
                />,
              ]}
            />
          </p>
        </div>
      )}

      {hasSsoError && (
        <div className="border-l-4 border-l-red-600 bg-gray-200 p-6 text-neutral-800">
          <p className="font-medium">{t("form.alert_boxes.sso_errors.title")}</p>
          <p>
            <p>{t("form.alert_boxes.sso_errors.message")}</p>
          </p>
        </div>
      )}

      {hasUnexpectedError && (
        <div className="border-l-4 border-l-red-600 bg-gray-200 p-6 text-neutral-800">
          <p className="font-medium">{t("form.alert_boxes.unexpected_error.title")}</p>
          <p>{t("form.alert_boxes.unexpected_error.message")}</p>
        </div>
      )}

      {(!isRequiredToUseSSO || emailCheckState === "false") && (
        <div>
          <button
            type="submit"
            className={[
              "rounded-full shadow bg-[#710ce2] text-white font-medium w-full px-6 py-3 leading-4 text-sm",
              navigation.state !== "idle" && "opacity-50 cursor-wait italic",
            ].join(" ")}
          >
            {navigation.state !== "idle" ? (
              <SpinnerIcon className="inline-block w-4 h-4 animate-spin" />
            ) : emailCheckState === "false" ? (
              t("form.submit_button.next_label")
            ) : (
              t("form.submit_button.label")
            )}
          </button>
        </div>
      )}

      {((hasSlugOptions && emailCheckState === "false") ||
        (emailCheckState === "true" && hasSSOOptions)) && (
        <div className="flex flex-row space-x-3 items-center justify-end">
          {emailCheckState === "false" ? (
            <div className="flex flex-row space-x-3 items-center">
              <span>or</span>
              {loaderData?.slugResult?.data.map((option: AuthOptions) => {
                return (
                  <div className="max-w-1/3">
                    <SSOButton {...option} />
                  </div>
                );
              })}
            </div>
          ) : (
            <SSOButton {...authOption} />
          )}
        </div>
      )}

      {(!isRequiredToUseSSO || emailCheckState === "false") && (
        <div className="text-center">
          <StyledLink to="/reset-password">{t("form.reset_password_link.label")}</StyledLink>
        </div>
      )}
    </Form>
  );
}
