import { AnchorHTMLAttributes } from "react";

export type StyledAnchorArgs = AnchorHTMLAttributes<HTMLAnchorElement>;

export function StyledAnchor({ children, ...props }: StyledAnchorArgs) {
  return (
    <a
      {...props}
      className="text-[#7453ab] underline underline-offset-2 decoration-2 decoration-blue-200 text-sm hover:underline"
    >
      {children}
    </a>
  );
}
