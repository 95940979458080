import * as React from "react";

export default function AnimatedLoader(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 150"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <circle cx="75" cy="50" r="30" fill="#00e4fe" />
      <circle cx="35" cy="120" r="30" fill="#8e24aa" />
      <circle cx="115" cy="120" r="30" fill="#ff77ff" />
    </svg>
  );
}
